<template>
    <sdCards title="Filters ">
        <a-form :model="formState" layout="vertical">
            <a-row :gutter="25">
                <a-col :span="6" v-for="(filter, index) in filters" :key="index">
                    <a-form-item :label="filter.name">
                        <a-input v-if="filter.type === 'text'" :value="filter.value" @input="updateValue(index, $event)" />

                        <a-input-number v-else-if="filter.type === 'number'" :value="filter.value"
                            @change="updateValue(index, $event)" />

                        <a-select v-model:value="formState.partnerId" v-else-if="filter.type === 'partner'"
                            placeholder="Please choose partner" show-search style="width: 100%" :options="partnerData"
                            :not-found-content="partnerFetching ? undefined : null" :filter-option="false"
                            @search="searchPartner">
                            <template v-if="partnerFetching" #notFoundContent>
                                <a-spin size="small" />
                            </template>
                        </a-select>

                        <a-date-picker v-else-if="filter.type === 'date'" :value="filter.value"
                            @input="updateValue(index, $event)" :style="{ width: '100%' }" format="DD/MM/YYYY" />

                        <a-select v-else-if="filter.type === 'apiList'" :size="'small'" :field-names="fieldNames"
                            :value="filter.value" :options="filter.options" :filter-option="selectFilterOption"
                            placeholder="Activities">
                            <!-- Customize the content of the a-select if needed -->
                        </a-select>
                    </a-form-item>
                </a-col>
                <a-col :span="6" class="button-container">
                    <a-button class="btn ant-btn-lg client-form-submit" outlined type="primary" @click="onFilter()">
                        <span>Filter</span>
                    </a-button>
                </a-col>
                <a-col :span="6" class="button-container">
                    <a-button @click="clearFilters()" danger>
                        <span>Clear Filters</span>
                    </a-button>
                </a-col>
            </a-row>
        </a-form>
    </sdCards>
</template>

<script setup>
import { ref, onMounted, reactive, defineEmits, defineProps, toRefs } from 'vue';
import { useStore } from 'vuex';
import usePartnerSearch from '../../composable/usePartnerSearch';
const emit = defineEmits(['onFilter'])
const props = defineProps(['filters']);
const { filters } = toRefs(props);
const dateRange = ref([]);
const formState = reactive({
    layout: 'vertical',
    number: '',
    partnerId: '',
    dateStart: null,
    dateEnd: null,
});

const { dispatch } = useStore();

onMounted(() => {
    dispatch('partnerInfo');
});

const clearFilters = () => {
    dateRange.value = [];
    formState.number = '';
    formState.dateStart = null;
    formState.dateEnd = null;
    formState.partnerId = '';
    emit('onFilter', formState);
};

const onFilter = () => {
    emit('onFilter', formState);
};

const selectFilterOption = (input, option) => {
    return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};
const fieldNames = { label: 'bankName', value: 'id' }; // Customize the field names based on your data structure


const { partnerData, searchPartner, partnerFetching } = usePartnerSearch(formState.partnerId);

</script>

<style lang="scss" scoped>
.custom,
.ant-calendar-picker-input.ant-input {
    height: 38px;
}

.button-container {
    align-self: center !important;

    .ant-btn {
        height: 38px;
        width: 100%;
    }
}

.table-actions {
    gap: 10px;
    display: flex;
}

.card-actions {
    gap: 10px;
    display: flex;
}
</style>
